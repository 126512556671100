$baseColor: #000000;
$baseBgColor: #FFFFFF;
$highlightColor: transparentize($baseColor, 0.5);
$linkColor: $highlightColor;

$lineColor: $highlightColor;
$ndhLine: solid $lineColor 1px;
$newsLine: solid $baseColor 1px;

$videoBgColor: transparentize($baseBgColor, 0.2);

$backgroundColor1: #f6f0f0;
$backgroundColor2: #fcc0bb;

$setOverlayColor1: transparentize($baseBgColor, 0.25);
$setOverlayColor2: transparentize($baseBgColor, 1);
$setOverlayColor3: transparentize($baseBgColor, 0.20);

$colorsSetLength: 15;
$colorsSetLighten: 25%;
$colorsSet: #C5E441, #48A70B, #B9E4DB, #8EC0BD, #37BAB4,
			#007F9E, #3D7BAE, #274C80, #1E2C8D, #EFAF05,
			#FF8724, #B32612, #8F1B42, #682E57, #C6DACF;
