@mixin ndh-container() {
	max-width: none;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	position: relative;

	padding-left: $mainPadding;
	padding-right: $mainPadding;

	&--titleImage,
	&--artist,
	&--exhibition {
        @include media("landscape") {
            max-width: $mainMaxWidth;
        }
        @include media("<xLarge", ">small") {
            max-width: $mainMaxWidthPlus;
        }
    }
}
