.ndh-exhibitionLink {
	display: inline-block;
	text-transform: none;
	// padding: 0.4em 0.6em;

	.ndh-pageExhibitions & {
		@include ndh-fontSize(h2);
	}

	&:hover {
		text-transform: none;
	}

	@for $i from 1 through $colorsSetLength {
		body.ndh-js-colorSet#{$i} & {
			color: transparentize(nth($colorsSet, $i), 0.3);
		}
	}
}
