
@use "sass:math";

.ndh-video {
	// @include ndh-container;
	position: relative;
	margin-bottom: $baseL;

	&--noMargin {
		margin-bottom: 0;
	}


	&__wrapper {
		position: relative;
	}

	&__playerBtn {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $setOverlayColor3;
		z-index: 1;

		.ndh-video.ndh-js-visiblePlayer & {
			display: none;
		}
	}
	&__icon {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: $base9;
		transform: translate(-50%, -50%);

		.ndh-video__wrapper:hover & {
			display: none;
		}
	}

	&__label {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.ndh-video__wrapper:hover & {
			display: block;
			color: $baseColor;
		}
	}

	&__preview {
		&--empty {
			padding-bottom: 30%;
		}

		img {
			width: 100%;
			object-position: center;
			object-fit: none;
			height: 100%;
		}

		.ndh-video.ndh-js-visiblePlayer & {
			display: none;
		}
	}

	&__title {
		margin-top: $baseS;
	}

	&__player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		&--removeAbsolute {
			position: relative;
		}

		figure {
	        position: relative;
	        &:last-child {
	            margin-bottom: 0;
	        }

	        &::before {
	            content: '';
	            display: block;
	            padding-bottom: (math.div(1, $videoPlayerRatio) * 100%);
	        }


	        > iframe {
	            position: absolute;
	            left: 0;
	            top: 0;
	            width: 100%;
	            height: 100%;
	        }
	    }
	}

	&__placeholder {
		position: relative;
		overflow: hidden;

		&--noHeight {
			height: 0;
		}
	}
}
