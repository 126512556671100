
.ndh-search {

	.ndh-body & {
		/* Remove First */
	   -webkit-appearance: none;
	   -moz-appearance: none;
	   appearance: none;

	   /* Then Style */
	   border-radius: 0px;

		display: block;
		width: 100%;
		border: 0;
		border-top: $newsLine;
		border-bottom: $newsLine;

		margin: $baseXL 0 0 0;
		padding: $baseL $baseS $baseL $baseS;


		font-size: 16px;

		@include media('>=small') {
			font-size: inherit;
		}

		&::placeholder {
			font-weight: normal;
			color: $baseColor;
		}


		&__emptyResultText {
			@include ndh-hiddenExhibition('hidden');

			&.ndh-js-visible {
				@include ndh-hiddenExhibition('visible');
				@include ndh-container;
				margin-top: $baseS;
			}
		}
	}
}
