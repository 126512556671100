
.ndh-artistNav {
	position: fixed;
	top: $mainMenuHeight;
	z-index: 3;
	width: 100%;
	
	body.ndh-js-mobileHide & {
		display: none;
	}
}
