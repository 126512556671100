
.ndh-nav {
	display: block;

	@include animation-show();
	body.ndh-js-mobileHide & {
		@include animation-hide();
	}

	&__list {
		background-color: transparent;
		@include media('>=xSmall') {
			background-color: $baseBgColor;
		}

		justify-content: space-between;
		padding: 0;

		@include media('>=xSmall') {
			display: flex;
			opacity: 1;
			margin-top: 0;
		}
	}

	&__elem {
		padding-left: $mainPadding;
		padding-right: $mainPadding;
		background-color: $baseBgColor;
		@include media('>=xSmall') {
			background-color: transparent;
		}
	}

	&--second {
		position: fixed;
		top: $mainMenuHeight;
		z-index: 3;
		width: 100%;

		@include animation-show;
		body.ndh-js-mobileHide & {
			@include animation-hide;
		}
		body.ndh-js-mainMenuOpen & {
			@include animation-hide;
		}
	}

	&__list--second {
		position: relative;

		border-bottom: $ndhLine;

		.ndh-nav__elemLink.ndh-js-menuSelected {
			color: $highlightColor;
		}
	}

	&__thirdContainer {
		position: relative;
	}

	&__list--third {
		position: absolute;
		top: 0;
		width: 100%;
		display: block;

		opacity: 0;
		@include animation-hide;
		&.ndh-js-menuSelected {
			@include animation-show;
		}

		border-bottom: $ndhLine;

		.ndh-nav__elemLink {
			text-transform: none;
			padding-top: $base2;
			padding-bottom: $base;
		}
	}

	&__elemLink {
		text-transform: uppercase;
		display: block;
		padding-top: $base - 0.1rem;
		padding-bottom: $base075;
		@include media('>small') {
			padding-top: $base05 + 0.1rem;
			padding-bottom: $base05 + 0.1rem;
		}
	}
}
