
.ndh-newsletter {
	&__formContainer {
		border: 0;
		display: block;
		//height: 430px;
		margin: 0 #{-$mainPadding} 0 #{-$mainPadding};
		width: 300px;
		overflow: hidden;
		height: 0;


		&.ndh-js-open.ndh-js-collapsedForHeightMeasuring,
		&.ndh-js-collapsedForHeightMeasuring {
			@include ease(height, 0);
			//NOTE: to override style-attr-css
			height: 0 !important;
			transition: height 0s ease-out 0s !important;
			visibility: hidden;
		}
	}

	&__form {

		font-size: 83%;
		@include ndh-container;
		padding-top: $baseM;
		padding-bottom: $baseM;

		p {
			margin-bottom: 0;

			&:last-of-type{
				margin-bottom: 0;
			}
		}

		form + br ,
		br + br {
			display: none;
		}

		border-spacing: $baseL;
		margin-top: $baseL;
		margin-bottom: $baseL;

		input {
			border: 0;
			border-bottom: $ndhLine;
			margin-bottom: $baseM;

			@include media('<xSmall') {
				width: 100%;
				margin-bottom: $baseM;
			}
		}


		input[type="text"]{
			width: 100%;
		}

		input[type="submit"]{
			text-transform: uppercase;
			border: 0;
			margin-bottom: $baseS;
			padding: 0;
			background: transparent;

			&:hover {
				@include ease(color);
				color: $linkColor;
			}
		}

		a, button {
			text-transform: uppercase;
			margin-bottom: $baseS;

			&:hover {
				@include ease(color);
				color: $linkColor;
			}
		}

		//NOTE hide phpList adwertisement
		& > div:last-child {
			display: none;
		}
	}


	&__ifame {
		border: 0;
		// border-top: solid red 1px;
		// border-bottom: solid green 1px;

		display: block;
		width: 100%;
		width: calc(100% + #{2 * $mainPadding});
		margin: 0 #{-$mainPadding} 0 #{-$mainPadding};
		// @include ease(height, $easeDuration);

		&.ndh-js-open {
			// @include ease(height, $easeDuration2);
			// @include ease(height, $easeDuration);
		}
		// &:not(.ndh-js-open) {
		// 	// NOTE: to override style-attr-css
		// 	height: 0 !important;
		// }
		&.ndh-js-open.ndh-js-collapsedForHeightMeasuring,
		&.ndh-js-collapsedForHeightMeasuring {
			@include ease(height, 0);
			//NOTE: to override style-attr-css
			height: 0 !important;
			transition: height 0s ease-out 0s !important;
			visibility: hidden;
		}
	}

	//NOTE iframe-body
	&__body {
		@include ndh-container;
		padding-top: $baseM;
		padding-bottom: $baseM;

		p {
			margin-bottom: 0;

			&:last-of-type{
				margin-bottom: 0;
			}
		}

		form + br ,
		br + br {
			display: none;
		}

		// tr {
		// 	vertical-align: top;
		// }
		table {
			border-spacing: $baseL;
			margin-top: $baseL;
			margin-bottom: $baseL;

			input {
				border: 0;
				border-bottom: $ndhLine;

				@include media('<xSmall') {
					width: 100%;
					margin-bottom: $baseM;
				}
			}

			td {
				@include media('<xSmall') {
					display: inline-block;
				}
				@include media('>=xSmall') {
					padding-bottom: $baseS;
				}
			}
		}
		td:first-child {
			padding-right: $baseS;
		}


		.list {
			list-style: none;
			margin: $baseS 0 0 0;
			padding: 0;

			.list {
				margin: 0 0 $baseS 0;

				input {
					margin-right: $baseS;
				}
			}
		}

		input[type="submit"]{
			text-transform: uppercase;
			border: 0;
			margin-bottom: $baseS;
			padding: 0;
			background: transparent;

			&:hover {
				@include ease(color);
				color: $linkColor;
			}
		}
		a {
			text-transform: uppercase;
		}

		//NOTE hide phpList adwertisement
		& > div:last-child {
			display: none;
		}
	}
}
