
@mixin animation-show() {
	animation-name: fadeInFromNone;
	animation-duration: $easeDuration;
	animation-timing-function: $easeFunction;
	animation-direction: normal;
	animation-fill-mode: forwards;
}

@mixin animation-hide() {
	animation-name: fadeOutToNone;
	animation-duration: $easeDuration;
	animation-timing-function: $easeFunction;
	animation-direction: normal;
	animation-fill-mode: forwards;
}

@keyframes fadeInFromNone {
	0% {
		margin-top: -10000px;
		z-index: -100;
		opacity: 0;
	}

	1% {
		margin-top: 0px;
		z-index: 0;
		opacity: 0;
	}

	100% {
		margin-top: 0px;
		opacity: 1;
	}
}

@keyframes fadeOutToNone {
	0% {
		margin-top: 0px;
		opacity: 1;
	}

	99% {
		margin-top: 0px;
		z-index: 0;
		opacity: 0;
	}

	100% {
		margin-top: -10000px;
		z-index: -100;
		opacity: 0;
	}
}
