
html, body {
	@include sansSerif();
}

html {
	background-color: $baseBgColor;
}

main {
	flex-grow: 1;
}


// h1 {
// 	@include ndh-fontSize(h1);
// 	@include margin-bottom(h1);
// }

h1, h2 {
	@include ndh-fontSize(h2);
	@include margin-bottom(h2);
	text-transform: uppercase;
	font-weight: normal;
}
h3 {
	@include ndh-fontSize(base);
	// @include margin-bottom(base);
	margin-bottom: $baseM;
	text-transform: uppercase;
	font-weight: normal;
}

p {
	text-align: left;
	@include margin-bottom;

	&:last-child {
		margin-bottom: 0;
	}

	a {
		text-decoration: none;
		// border-bottom: 1px solid currentColor;
	}

	a:hover {
		color: $linkColor;
	}
}


a, a:hover, a:active, a:visited {
	outline: 0 !important;
	text-decoration: none;
	text-transform: uppercase;
	@include ease(color);
}
a:hover {
	color: $linkColor;
}
