
// Create a grid (with one or more rows)
// It does not use negative margins nor internal padding to define the gutters, so you can easily
// define borders for both row containers and / or cells without any additional markup
// Remember to provide a fallback for ie-9
//
// $columns:       can be a single number (it means that every column has the same width) or
//                 expressed like 3 2 3 to define width proportions among the columns
// $hGutter:       the horizontal space between two columns.
// $vGutter:       the vertical space between two rows.
// $rows:          number of rows
// $invertOrder:   make sense only if there are more than one row. When it is true, it allows to have the content
//                 defined by columns (so it is easy to fallback to a single column layout for smaller screens)
// $childSelector: selector to target the cells of the grid

@use "sass:math";

@mixin flexGrid($columns, $hGutter: 0, $vGutter: 0, $rows: 1, $invertOrder: false, $childSelector: '> *', $alignItems: stretch, $alignContent: stretch) {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: $alignItems;
    align-content: $alignContent;

    #{$childSelector} {
        position: relative;
        box-sizing: border-box;
        // flex: grow shrink basis
        flex: 0 1 auto;
    }

    $colCount: $columns;
    $internalColCount: $columns;
    @if (length($columns) > 1) {
        $colCount: length($columns);
        $internalColCount: 0;
        @each $col in $columns {
            $internalColCount: $internalColCount + $col;
        }
    }

    // @if ($rows > 0) {
    //
    // } @else {
    //
    // }

    $i: 1;
    @for $col from 1 through $colCount {
        @for $row from 0 through $rows - 1 {
            #{$childSelector}:nth-child(#{$i}) {
                $factor: if(length($columns) > 1, nth($columns, $col), 1);
                $order: if($rows > 1 and $invertOrder, ($row * $colCount) + $col, $i);
                $notFirstRow: $order > $colCount;
                $notFirstCol: ($order - 1) % $colCount != 0;

                // use the width instead of flex-basis to account these bugs:
                // https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
                // https://github.com/philipwalton/flexbugs#8-flex-basis-doesnt-support-calc
                width: calc(#{math.div(100% * $factor, $internalColCount)} - #{math.div($hGutter * ($colCount - 1), $colCount)});
                order: $order;
                @if ($notFirstRow) {
                    margin-top: $vGutter;
                } @else {
					margin-top: 0;
				}
                @if ($notFirstCol) {
                    // fix aproximation issues in IE making the layout broken
                    margin-left: $hGutter * 0.999;
                } @else {
					margin-left: 0;
				}
            }
            $i: $i + 1;
        }
    }
}
