
.ndh-newsList {
	border-bottom: $newsLine;

	&__elem {
		border-top: $newsLine;

		&:first-child {
			border-top: 0;
		}
	}

	&__link {
		@include ndh-container;
		display: block;
		padding-bottom: $baseM - $baseS;

		&[data-ndh-button-mode]:hover {
			cursor: pointer;
		}
	}

	&__title {
		@include ndh-fontSize(h2);
		margin-top: $baseS;
		margin-bottom: $baseS;

		@include media('>=xSmall') {
			@include ndh-fontSize(h1);
		}
		.ndh-newsList__link[data-ndh-button-mode] &,
		&--linked {
			html:not(.mobile) &:hover {

				@include hover-outline(1px);
			}
		}
	}

	&__imgWrapper {
		font-size: 0;
		@include media('>=xSmall') {

		}
	}

	img {
		margin-top: $baseS;
		margin-bottom: $baseS;
		max-width: 100%;

		@include media("landscape") {
			max-width: $maxImageWidth;
		};
	}

	&__text {
		margin-bottom: $baseS;
		text-transform: none;

		&--withPadding {
			padding-bottom: $base;
		}
		&:hover {
			text-transform: none;

			// //TODO: @ssh: use border but askt stf before
			// text-decoration: underline;
		}
	}

	&__accordionItemBody {
	   position: relative;
	   overflow: hidden;
	   height: auto;
   }


   &__accordionItemContent {
	   @include fadeout();
	   position: absolute;
	   left: 0;
	   top: 0;
	   width: 100%;

	   [data-ndh-accordion-item][data-ndh-collapsed="false"] & {
		   @include fadein;
		   position: relative;
	   }
   }
}
