
// user generated content Style:

@use "sass:math";

.ndh-ugc {
    h1 {
        margin-top: $baseXL;
        margin-bottom: $baseXL;
		font-weight: normal;
    }

    p {
        max-width: $paragraphMaxWidth;
    }

    q, blockquote {
        quotes: "»" "«" "\00AB" "\00BB";
    }

    blockquote {
        margin: 0 0 0 1em;

        > p {
            &::before {
                content: open-quote;
            }


            &::after {
                content: close-quote;
            }
        }
    }


	figure.video {
		position: relative;
		@include margin-bottom;

		&:last-child {
			margin-bottom: 0;
		}


		&::before {
			content: '';
			display: block;
			padding-bottom: (math.div(1, $videoPlayerRatio) * 100%);
		}


		> iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}


	ul {
		padding-left: 0;

		> li {
            position: relative;
			margin-left: 1em;
			padding-left: 0.5em;



    		&::before {
    			content: '';
    			position: absolute;
    			background-clip: content-box;
    			left: -1em;
    			top: 50%;
    			width: 1em;// * $sqrt2);
    			// 1px is not enough for shitty old Safari, so...
    			height: 1.04px;
    			//transform-origin: 0 0;
    			transform: rotate(-45deg);
    			backface-visibility: hidden;
    			transform-style: preserve-3d;
    			background-color: currentColor;
    		}
		}
	}


}
