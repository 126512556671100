@font-face{
    font-family:'Futura LT W02 Medium';
    src:url('../fonts/37422829-7c27-4cfa-a44e-c89401db871f.eot?#iefix');
    src:url('../fonts/37422829-7c27-4cfa-a44e-c89401db871f.eot?#iefix') format('eot'),url('../fonts/df488f3b-65ff-4eaa-ac06-b45f87eda344.woff2') format('woff2'),url('../fonts/a7a0d534-c650-4c74-8292-f126c72094fb.woff') format('woff'),url('../fonts/fe066f8b-b3bf-47ec-b93a-e1197652ccdc.ttf') format('truetype'),url('../fonts/8820a52d-4d4e-419f-b4f4-12df6c1c9cfa.svg#8820a52d-4d4e-419f-b4f4-12df6c1c9cfa') format('svg');
}
@font-face{
    font-family:'Futura Outline';
    src: url('../fonts/futuratmedou1-webfont.eot');
    src: url('../fonts/futuratmedou1-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futuratmedou1-webfont.woff2') format('woff2'),
         url('../fonts/futuratmedou1-webfont.woff') format('woff'),
         url('../fonts/futuratmedou1-webfont.svg#futuratmedou1regular') format('svg');
}


@mixin sansSerif() {
    font-family: 'Futura LT W02 Medium', Helvetica, arial, sans-serif;
}

@mixin fontOutline() {
    font-family: 'Futura Outline', Helvetica, arial, sans-serif;
}
