
@mixin animation-show-contrast() {
	animation-name: showContrast;
	animation-duration: $easeDuration2 * 3;
	animation-timing-function: $easeInOut;
	animation-delay: 0s;
	animation-direction: normal;
	animation-fill-mode: forwards;
}


@keyframes showContrast {
	0% {
		opacity: 0;
		//filter:	contrast(200%) brightness(200%);
	}

	100% {
		opacity: 1;
		//filter:	contrast(100%) brightness(100%);
	}
}
