
.ndh-doubleImages {
	position: relative;


	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
		width: $base3;
	}


	&__texts {
		margin-bottom: $baseL;
	}


	&__columns {
		margin-bottom: 0;
	}


	&__column:nth-child(1) {
		margin-bottom: $baseL;
		@include media('>=small') {
			margin-bottom: 0;
		}
	}


	@include media('>=small') {
		&__columns:hover {
			.ndh-doubleImages__column {
				opacity: 0.1;
				//@include ease(opacity, $easeDuration4);
			}
		}

		.ndh-doubleImages__column {
			.ndh-doubleImages__image--right {
				figure {
					transform: translateX(calc(-50% - 1.5rem));
				}
			}

			&:hover {
				opacity: 1;
				//@include ease(opacity, $easeDuration2);

				& ~ .ndh-doubleImages__column {
					opacity: 0.1;
				}

				.ndh-doubleImages__image {
					pointer-events: none;
					position: relative;
					overflow: visible;
					z-index: 3;
					//@include animation-show-contrast();
				}

				.ndh-doubleImages__image--right {
					left: calc( -100% - 3rem);
					opacity: 1;
					//@include animation-show-contrast();
					figure {
						transform: translateX(0%);
					}
				}
			}
		}

		&__image {
			width: 100%;
			overflow: hidden;

			@include media('>=small') {
				figure {
					width: calc( 200% + 3rem);
				}
			}
		}
	}
}
