
.ndh-contentImages {
	@include ndh-container;

	&--titleImage {
	}

	.ndh-container + &{
		margin-top: $baseL;
	}

	figcaption {
		margin-top: $base075;
		margin-bottom: 0;

		@include media("landscape") {
			max-width: $maxImageWidth;
		};
	}

	p {
		margin-top: $base075;
		margin-bottom: 0;
	}

	&__placeholder {
		position: relative;
	}

	&__elem {
		margin-bottom: $baseXXL;
		max-width: 100%;
		height: auto;

		.ndh-pageExhibitions--focus & {
			margin-bottom: $baseM;
		}

		@include media("landscape") {
			max-width: $maxImageWidth;
			height: auto;
		};

		img {
			width: 100%;
			position: absolute;
		}

		&--single {
			@include media('>=small') {
				width: 50%;
				padding-right: $baseS * 0.5;
			}
		}

		&--double {
			img {
				bottom: 0;
			}

			& *:nth-child(2) {
				//margin-bottom: $baseL;
			}
			@include media('>=small') {
				& *:nth-child(2) {
					//margin-bottom: 0;
				}
			}


			@include media('>=small') {
				@include flexGrid(1 1, $base3, 0, 1, false);
				//check artist image strea/wall
				// align-items: flex-end;
			}
		}

		&--fullWidth {
			img {
				width: 100%;
				max-width: none;

				@include media("landscape") {
					max-width: none;
				};
			}
		}

		&--contact {
			max-width: none;

			@include media("landscape") {
				max-width: none;
			}
			@include media('>=small') {
				max-width: none;
			}

			img {
				position: static;
			}
		}
	}
}
