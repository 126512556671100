.ndh-imprint {
	&__head {
		margin-top: $baseM;
		margin-bottom: $baseM;
	}


	&__exhibitionLink {
		display: inline-block;
		padding: 0.4em 0.6em;

		@for $i from 1 through $colorsSetLength {
			body.ndh-js-colorSet#{$i} & {
				background-color: transparentize(nth($colorsSet, $i), 0.5);
			}
		}
	}
}
