
@use "sass:math";

$base: 1rem;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;

$base05: 0.5rem;
$base075: 0.75rem;

//TODO: use 10 * n
$baseS: $base * .9;
$baseM: $base * 1.9;
$baseL: $base * 2.7;
$baseXL: $base * 3.6;
$baseXXL: $base * 5.0;

$paragraphMaxWidth: $base * 66;
$mainPadding: $baseS;

$mainMenuHeight: $base4;

$videoPlayerRatio: math.div(16, 9);
$slideshowImageRatio: math.div(16, 11);

$logoWidth: 238px;
$logoHeight: 18px;
$logoWidthSmall: 208px;
$logoHeightSmall: 16px;


$mainMaxWidth: 62.5%;
$mainMaxWidthPlus: 87.5%;
$maxImageWidth: 62.5vw;
