
@mixin ndh-hiddenExhibition($display: 'hidden') {
	@if $display == 'visible' {
		opacity: 1;
		height: auto;
		background: white;
		overflow: visible;

		//NOTE: slow in
		@include ease(opacity margin, $easeDuration2);

	} @else {
		opacity: 0;
		height: 0;
		margin-top: 0;
		margin-bottom: 0;
		overflow: hidden;

		//NOTE: fast out
		@include ease(opacity margin, $easeDuration);
	}
}
