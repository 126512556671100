
@mixin animation-show-down() {
	animation-name: fadeInFromNoneDown;
	animation-duration: $easeDuration;
	animation-delay: $easeDuration05;
	animation-timing-function: $easeInOutQuad;
	animation-fill-mode: forwards;
	opacity: 0;
}

@mixin animation-hide-down() {
	animation-name: fadeOutToNoneDown;
	animation-duration: $easeDuration;
	animation-timing-function: $easeInOutQuad;
	animation-fill-mode: forwards;
	opacity: 1;
}

@keyframes fadeInFromNoneDown {
	0% {
		margin-top: -10000px;
		z-index: -100;
		opacity: 0;
	}

	1% {
		z-index: 0;
		opacity: 0;
		margin-top: $base05;
	}

	100% {
		opacity: 1;
		margin-top: 0;
	}
}

@keyframes fadeOutToNoneDown {
	0% {
		opacity: 1;
		margin-top: 0;
	}

	99% {
		z-index: 0;
		opacity: 0;
		margin-top: $base05;
	}

	100% {
		margin-top: -10000px;
		z-index: -100;
		opacity: 0;
	}
}
