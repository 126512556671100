
.ndh-container {
	@include ndh-container;


	&--contentColumn {
		@include media("landscape") {
			max-width: $maxImageWidth;
		};
	}

	& + &,
	.ndh-search + & {
		@include media('>=small') {
			margin-top: $baseXL;
		}
	}

	&--2columns,
	&__2columns {
		@include media('>=small') {
			@include flexGrid(1 1, $baseM, $baseS, 1, false);
		}
	}

    &--3columns,
    &__3columns {
		@include media('>=small') {
			@include flexGrid(1 1 1, $baseM, $baseS, 1, false);
		}
		
		&--hidable {
			&.ndh-js-hidden {
				display: none;
			}
		}
    }
	
	&__column {
		@include ndh-hiddenExhibition('visible');
		&.ndh-js-hidden {
			@include ndh-hiddenExhibition('hidden');
		}
    }

	@include ndh-hiddenExhibition('visible');
	&.ndh-js-hidden {
		@include ndh-hiddenExhibition('hidden');
	}
}
