$easeDuration: 0.1s;
$easeDuration2: $easeDuration * 2;
$easeDuration3: $easeDuration * 3;
$easeDuration4: $easeDuration * 4;

$easeDuration05: $easeDuration * 0.5;

$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940); /* easeOutQuad */
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
$easeLinear: linear;

//basic ease function
$easeFunction: $easeLinear;
$easeOut: $easeOutQuad;
$easeInOut: $easeInOutQuad;

$longColorAnimationDuration: 5s;
