
.ndh-body {
    visibility: visible;
    display: flex;
	flex-direction: column;
	min-height: 100vh;
    opacity: 1;
    padding-top: $mainMenuHeight;

    @include ease(opacity, $easeDuration2);

	opacity: 0;
	
	body.ndh-js-loaded & {
		opacity: 1;
	}
	// body.ndh-js-loading & {
	// 	opacity: 0;
	// }
}
