
.ndh-readMore {
	margin-bottom: $baseXL;

	& + & {
		margin-bottom: $baseXXL;
	}

	&__head {
		margin-top: $baseL;
		margin-bottom: $baseL;
		max-width: $paragraphMaxWidth;

		&--exhibition {
			margin-bottom: $baseS;
		}
	}

	&__btn {
		margin-top: $baseM;
		display: block;
		overflow: hidden;
	}

	&__txt {
		display: none;
	}
}
