
@use "sass:math";

.ndh-exhibitionPreview {
	@include ndh-container;
	display: block;
	//padding-bottom: $baseL - $baseM;

	margin-top: $baseXL;
	margin-bottom: 0;


	&__placeholder {
		position: relative;
		height: 0;
	}

	&__title {
		margin-bottom: $baseM;
	}

	&__metaTitleDate {
		text-transform: none;
	}

	&__img {
		height: 0;
		overflow: hidden;
		padding-bottom: (math.div(1, $slideshowImageRatio) * 100%);
		position: relative;
		width: 100%;

		img {
			@include objectFit(cover);
			height: 100%;
			position: absolute;
			user-select: none;
			width: 100%;
		}
	}
}
