@use "sass:math";

.ndh-contact {
    @function ndh-columns($num) {
        @return calc(#{math.div(100%, $num)} - #{math.div($base3 * 2, $num)})
    }

    @include ndh-container;

	border-bottom: 1px solid $baseColor;
    display: flex;
    justify-content: space-between;
    padding-bottom: $baseL;
    flex-wrap: wrap;


    &__column {
        width: ndh-columns(1);
        
        @include media('>=xSmall') {
            width: ndh-columns(2);
        }

        @include media('>=medium') {
            width: ndh-columns(3);
        }

        .ndh-contact--additonal & {
            width: 100%;
        }
    }
}