.ndh-artistList {
	overflow: hidden;
	position: relative;

	@for $i from 1 through $colorsSetLength {
		body.ndh-js-colorSet#{$i} & {
			background-color: nth( $colorsSet, $i );
		}
	}

	&__elem {
		@include ndh-fontSize(h2);

		border-bottom: 1px solid $baseBgColor;
		color: default;
		display: block;
		padding-bottom: $base05;
		padding-left: $mainPadding;
		padding-right: 0;
		padding-top: $base05;
		position: relative;
		transition: none;

		@include media('>=xSmall') {
			@include ndh-fontSize(artistsNav);
			border-bottom: none;
			display: inline;
			padding: 0;
			padding-right: $base3;

			@include media('portrait') {
				@include ndh-fontSize(artistsNavPortrait);
			}
		}

		&:hover {
			@include hover-outline;
		}

		&::before {
			border-bottom: 1px solid $baseBgColor;
			bottom: 0;
			content: '';
			left: -200vw;
			position: absolute;
			width: 400vw;
		}
	}

	&__list {
		@include media('>=xSmall') {
			padding-left: $base3;
			padding-right: $base;
			//@include notebookLines($baseBgColor, $artistNavTextSize * $artistNavLineHeight );
		}
	}

	&__overlay {
		background: linear-gradient(to bottom, $setOverlayColor1 0%, $setOverlayColor2 100%);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
