.ndh-externalLink {
	margin-left: 0;
	@include ease(margin-left color);
    &:hover {
		@include ease(margin-left color);
		margin-left: $base05;
		color: $linkColor;
	}

	&--onlyEase {
		&:hover {
			@include ease(color);
			color: $linkColor;
		}
	}
}
