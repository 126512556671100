
.ndh-exhibitionList {

	&__elem {
		margin-bottom: $baseM;

		.ndh-pageExhibitions--focus & {
			margin-bottom: $baseXXL;

			&:last-child {
				margin-bottom: $baseXXL;
			}
		}


		&--lowercase {
			text-transform: none;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include ndh-hiddenExhibition('visible');
		&.ndh-js-hidden {
			@include ndh-hiddenExhibition('hidden');
		}
	}


	.ndh-exhibitionList__elemImg {
		img {
			width: 100%;
			margin-bottom: $baseM;
		}
	}
}
