.ndh-slideshow {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &__button {
        $margin: 0;

        width: 50%;
        padding: $base2;
        fill: currentColor;
        stroke: none;
        
        &:hover {
            color: $highlightColor;
            fill: $highlightColor;
        }
        
        @include media('>=small') {
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
            width: auto;
            padding: $base;
		}
        @include media('>=medium') {
            padding: $base2;
        }

        .ndh-slideshow.ndh-js-loading &,
        .ndh-slideshow.ndh-js-disabled &{
            pointer-events: none;
            opacity: 0.25;

            &:hover {
                cursor: default;
            }
        }

        &--prev {
            left: 0;

            @include media('>=small') {
                left: auto;
                margin-right: $margin;
                right: 100%;
            }

            svg {
                transform: scaleX(-1);
            }
        }
        
        &--next {
            right: 0;

            @include media('>=small') {
                left: 100%;
                margin-left: $margin;
                right: auto;
            }
        }

        svg {
            height: $baseL;
            width: $baseM;
        }
    }


    &__slide {
        @include ease(opacity, $easeDuration2);
        flex-shrink: 0;
        opacity: 0;
        pointer-events: none;
        position: relative;
        width: 100%;

        @for $i from 0 through 2 {
            &:nth-of-type(#{$i + 1}) {
                left: #{$i * -100%};
            }
        }

        &.ndh-js-current {
            // display: block;
            opacity: 1;
            pointer-events: all;
            z-index: 1;
        }
    }

    &__slides {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        width: 100%;
    }
}