.ndh-buttonHamburger {
	display: block;
	z-index: 3;
	width: $base2;
	margin-top: $base * 0.3;
	@include media('>=small') {
		margin-top: $base * 0.4;
	}

  @include ease(opacity);
  &:hover {
    opacity: 0.5;
  }

	span {
		display: block;
		position: relative;

		height: 1px;

		background-color: $baseColor;

		margin-bottom: $base05;

		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(3) {
			bottom: 0;
		}
	}

	body.ndh-js-mainMenuOpen & {
    span {
			&:nth-child(1) {
				transform: rotate(45deg);
				top: $base05 + 0.1rem;
			}
			&:nth-child(2) {
				width: 0;
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
				bottom: $base05 + 0.1rem;
			}
		}
  }
}
