
.ndh-linkList {
	@include ndh-container;
	padding-top: $baseS;
	margin-bottom: $baseL;

	&--sozial {
		display: flex;
		justify-content: space-between;
		flex-grow: 0;
		flex-wrap: wrap;
		margin-bottom: 0;
	}

	&--artist {
		padding-top: $baseM;
		margin-bottom: $baseXL;
	}

	&--imprint {
		padding-top: $baseM;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0;
		max-width: none;
	}

	&__elem {
		margin-bottom: $baseS;
		margin-right: $base;

		.ndh-linkList--imprint &,
		&:last-child {
			margin-bottom: 0;
		}

		&--firstRow {

		}
	}

	&__link {
		text-transform: uppercase;
		position: relative;
	}
}
