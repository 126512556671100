
@mixin animation-show-up() {
	animation-name: fadeInFromNoneUp;
	animation-duration: $easeDuration;
	animation-delay: $easeDuration05;
	animation-timing-function: $easeFunction;
	animation-fill-mode: forwards;
	opacity: 0;
}

@mixin animation-hide-up() {
	animation-name: fadeOutToNoneUp;
	animation-duration: $easeDuration;
	animation-timing-function: $easeFunction;
	animation-fill-mode: forwards;
	opacity: 1;
}

@keyframes fadeInFromNoneUp {
	0% {
		margin-top: -10000px;
		z-index: -100;
		opacity: 0;
	}

	1% {
		z-index: 0;
		opacity: 0;
		margin-top: -$base05;
	}

	100% {
		opacity: 1;
		margin-top: 0;
	}
}

@keyframes fadeOutToNoneUp {
	0% {
		opacity: 1;
		margin-top: 0;
	}

	99% {
		z-index: 0;
		opacity: 0;
		margin-top: -$base05;
	}

	100% {
		margin-top: -10000px;
		z-index: -100;
		opacity: 0;
	}
}
