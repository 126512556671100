
.ndh-header {
	z-index: 4;
	height: $mainMenuHeight;
	position: fixed;
	width: 100%;

	background-color: $baseBgColor;

	border-bottom: solid $baseColor 1px;
	body.ndh-js-mainMenuOpen & {
		@include media('<=xSmall') {
			border-bottom: solid $baseBgColor 1px;
		}
	}
	body.ndh-js-mobileHide & {
		@include animation-hide();
	}

	opacity: 0;
	visibility: hidden;

	body.ndh-js-loaded & {
		visibility: visible;
		@include ease(opacity);
		opacity: 1;
	}

	&__logo {
		position: absolute;
		top: $base - 0.1;
		right: $mainPadding;
		bottom: $baseS;
		line-height: 0;

		animation-delay: 0s;
		@include animation-show-down;
		body.ndh-js-mainMenuOpen & {
			@include media('>=xSmall') {
				@include animation-hide-down;
			}
		}

		svg {
			width: $logoWidthSmall;
			height: $logoHeightSmall;
		}

		@include media('>=small') {
			top: $base;
			svg {
				width: $logoWidth;
				height: $logoHeight;
			}
		}
	}

	&__nav {
		display: none;
		border-bottom: solid $baseBgColor 1px;
		@include ease(border-bottom);
		body.ndh-js-mainMenuOpen & {
			@include media('<=xSmall') {
				border-bottom: solid $baseColor 1px;
			}
		}
	}

	&__navElem {
		visibility: hidden;
		body.ndh-js-loaded & {
			visibility: visible;
		}

		@include animation-hide;
		body.ndh-js-mainMenuOpen & {
			@include animation-show;
		}
		@include media('>=xSmall') {
			@include animation-hide-up;
			body.ndh-js-mainMenuOpen & {
				@include animation-show-up;
			}
		}
	}

	&__navOpenBtn {
		position: relative;
		display: block;
		padding-left: $mainPadding;
		padding-right: $mainPadding;
		//background-color: $baseBgColor;
		@include media('>=xSmall') {
			background-color: transparent;
		}
	}

	&__navOpenBtnLink {
		text-transform: uppercase;
		display: block;
		padding-top: $baseS;
		padding-bottom: $baseS;
		@include media('>small') {
			padding-top: $base075;
			padding-bottom: $base075;
		}

		body.ndh-js-mainMenuOpen & {
			color: $highlightColor;
		}
	}
}
