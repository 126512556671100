@charset "UTF-8";
/* easeOutQuad */
/* easeInOutQuad */
@font-face {
  font-family: "Futura LT W02 Medium";
  src: url("../fonts/37422829-7c27-4cfa-a44e-c89401db871f.eot?#iefix");
  src: url("../fonts/37422829-7c27-4cfa-a44e-c89401db871f.eot?#iefix") format("eot"), url("../fonts/df488f3b-65ff-4eaa-ac06-b45f87eda344.woff2") format("woff2"), url("../fonts/a7a0d534-c650-4c74-8292-f126c72094fb.woff") format("woff"), url("../fonts/fe066f8b-b3bf-47ec-b93a-e1197652ccdc.ttf") format("truetype"), url("../fonts/8820a52d-4d4e-419f-b4f4-12df6c1c9cfa.svg#8820a52d-4d4e-419f-b4f4-12df6c1c9cfa") format("svg");
}
@font-face {
  font-family: "Futura Outline";
  src: url("../fonts/futuratmedou1-webfont.eot");
  src: url("../fonts/futuratmedou1-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/futuratmedou1-webfont.woff2") format("woff2"), url("../fonts/futuratmedou1-webfont.woff") format("woff"), url("../fonts/futuratmedou1-webfont.svg#futuratmedou1regular") format("svg");
}
@keyframes showContrast {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/**
 * Positioning shorthand mixin.
 */
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes fadeOutToNone {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, 50% 50%);
*/
@keyframes fadeInFromNone {
  0% {
    margin-top: -10000px;
    z-index: -100;
    opacity: 0;
  }
  1% {
    margin-top: 0px;
    z-index: 0;
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}
@keyframes fadeOutToNone {
  0% {
    margin-top: 0px;
    opacity: 1;
  }
  99% {
    margin-top: 0px;
    z-index: 0;
    opacity: 0;
  }
  100% {
    margin-top: -10000px;
    z-index: -100;
    opacity: 0;
  }
}
@keyframes fadeInFromNoneUp {
  0% {
    margin-top: -10000px;
    z-index: -100;
    opacity: 0;
  }
  1% {
    z-index: 0;
    opacity: 0;
    margin-top: -0.5rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fadeOutToNoneUp {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  99% {
    z-index: 0;
    opacity: 0;
    margin-top: -0.5rem;
  }
  100% {
    margin-top: -10000px;
    z-index: -100;
    opacity: 0;
  }
}
@keyframes fadeInFromNoneDown {
  0% {
    margin-top: -10000px;
    z-index: -100;
    opacity: 0;
  }
  1% {
    z-index: 0;
    opacity: 0;
    margin-top: 0.5rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fadeOutToNoneDown {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  99% {
    z-index: 0;
    opacity: 0;
    margin-top: 0.5rem;
  }
  100% {
    margin-top: -10000px;
    z-index: -100;
    opacity: 0;
  }
}
.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

html, body {
  font-family: "Futura LT W02 Medium", Helvetica, arial, sans-serif;
}

html {
  background-color: #FFFFFF;
}

main {
  flex-grow: 1;
}

h1, h2 {
  font-size: 2.8875rem;
  line-height: 1.15;
  margin-bottom: 2.7rem;
  text-transform: uppercase;
  font-weight: normal;
}
@media (min-width: 769px) {
  h1, h2 {
    font-size: 3.3rem;
  }
}

h3 {
  font-size: 1.4875rem;
  line-height: 1.5;
  margin-bottom: 1.9rem;
  text-transform: uppercase;
  font-weight: normal;
}
@media (min-width: 769px) {
  h3 {
    font-size: 1.7rem;
  }
}

p {
  text-align: left;
  margin-bottom: 1.9rem;
}
p:last-child {
  margin-bottom: 0;
}
p a {
  text-decoration: none;
}
p a:hover {
  color: rgba(0, 0, 0, 0.5);
}

a, a:hover, a:active, a:visited {
  outline: 0 !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.1s linear 0s;
}

a:hover {
  color: rgba(0, 0, 0, 0.5);
}

.ndh-ugc h1 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
  font-weight: normal;
}
.ndh-ugc p {
  max-width: 66rem;
}
.ndh-ugc q, .ndh-ugc blockquote {
  quotes: "»" "«" "«" "»";
}
.ndh-ugc blockquote {
  margin: 0 0 0 1em;
}
.ndh-ugc blockquote > p::before {
  content: open-quote;
}
.ndh-ugc blockquote > p::after {
  content: close-quote;
}
.ndh-ugc figure.video {
  position: relative;
  margin-bottom: 1.9rem;
}
.ndh-ugc figure.video:last-child {
  margin-bottom: 0;
}
.ndh-ugc figure.video::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.ndh-ugc figure.video > iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ndh-ugc ul {
  padding-left: 0;
}
.ndh-ugc ul > li {
  position: relative;
  margin-left: 1em;
  padding-left: 0.5em;
}
.ndh-ugc ul > li::before {
  content: "";
  position: absolute;
  background-clip: content-box;
  left: -1em;
  top: 50%;
  width: 1em;
  height: 1.04px;
  transform: rotate(-45deg);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  background-color: currentColor;
}

.ndh-body {
  visibility: visible;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1;
  padding-top: 4rem;
  transition: opacity 0.2s linear 0s;
  opacity: 0;
}
body.ndh-js-loaded .ndh-body {
  opacity: 1;
}

.ndh-header {
  z-index: 4;
  height: 4rem;
  position: fixed;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: solid #000000 1px;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 504px) {
  body.ndh-js-mainMenuOpen .ndh-header {
    border-bottom: solid #FFFFFF 1px;
  }
}
body.ndh-js-mobileHide .ndh-header {
  animation-name: fadeOutToNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
body.ndh-js-loaded .ndh-header {
  visibility: visible;
  transition: opacity 0.1s linear 0s;
  opacity: 1;
}
.ndh-header__logo {
  position: absolute;
  top: 0.9rem;
  right: 0.9rem;
  bottom: 0.9rem;
  line-height: 0;
  animation-delay: 0s;
  animation-name: fadeInFromNoneDown;
  animation-duration: 0.1s;
  animation-delay: 0.05s;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-fill-mode: forwards;
  opacity: 0;
}
@media (min-width: 504px) {
  body.ndh-js-mainMenuOpen .ndh-header__logo {
    animation-name: fadeOutToNoneDown;
    animation-duration: 0.1s;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-fill-mode: forwards;
    opacity: 1;
  }
}
.ndh-header__logo svg {
  width: 208px;
  height: 16px;
}
@media (min-width: 768px) {
  .ndh-header__logo {
    top: 1rem;
  }
  .ndh-header__logo svg {
    width: 238px;
    height: 18px;
  }
}
.ndh-header__nav {
  display: none;
  border-bottom: solid #FFFFFF 1px;
  transition: border-bottom 0.1s linear 0s;
}
@media (max-width: 504px) {
  body.ndh-js-mainMenuOpen .ndh-header__nav {
    border-bottom: solid #000000 1px;
  }
}
.ndh-header__navElem {
  visibility: hidden;
  animation-name: fadeOutToNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
body.ndh-js-loaded .ndh-header__navElem {
  visibility: visible;
}
body.ndh-js-mainMenuOpen .ndh-header__navElem {
  animation-name: fadeInFromNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
@media (min-width: 504px) {
  .ndh-header__navElem {
    animation-name: fadeOutToNoneUp;
    animation-duration: 0.1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 1;
  }
  body.ndh-js-mainMenuOpen .ndh-header__navElem {
    animation-name: fadeInFromNoneUp;
    animation-duration: 0.1s;
    animation-delay: 0.05s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}
.ndh-header__navOpenBtn {
  position: relative;
  display: block;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}
@media (min-width: 504px) {
  .ndh-header__navOpenBtn {
    background-color: transparent;
  }
}
.ndh-header__navOpenBtnLink {
  text-transform: uppercase;
  display: block;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}
@media (min-width: 769px) {
  .ndh-header__navOpenBtnLink {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
body.ndh-js-mainMenuOpen .ndh-header__navOpenBtnLink {
  color: rgba(0, 0, 0, 0.5);
}

.ndh-nav {
  display: block;
  animation-name: fadeInFromNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
body.ndh-js-mobileHide .ndh-nav {
  animation-name: fadeOutToNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.ndh-nav__list {
  background-color: transparent;
  justify-content: space-between;
  padding: 0;
}
@media (min-width: 504px) {
  .ndh-nav__list {
    background-color: #FFFFFF;
  }
}
@media (min-width: 504px) {
  .ndh-nav__list {
    display: flex;
    opacity: 1;
    margin-top: 0;
  }
}
.ndh-nav__elem {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  background-color: #FFFFFF;
}
@media (min-width: 504px) {
  .ndh-nav__elem {
    background-color: transparent;
  }
}
.ndh-nav--second {
  position: fixed;
  top: 4rem;
  z-index: 3;
  width: 100%;
  animation-name: fadeInFromNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
body.ndh-js-mobileHide .ndh-nav--second {
  animation-name: fadeOutToNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
body.ndh-js-mainMenuOpen .ndh-nav--second {
  animation-name: fadeOutToNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.ndh-nav__list--second {
  position: relative;
  border-bottom: solid rgba(0, 0, 0, 0.5) 1px;
}
.ndh-nav__list--second .ndh-nav__elemLink.ndh-js-menuSelected {
  color: rgba(0, 0, 0, 0.5);
}
.ndh-nav__thirdContainer {
  position: relative;
}
.ndh-nav__list--third {
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  opacity: 0;
  animation-name: fadeOutToNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
  border-bottom: solid rgba(0, 0, 0, 0.5) 1px;
}
.ndh-nav__list--third.ndh-js-menuSelected {
  animation-name: fadeInFromNone;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.ndh-nav__list--third .ndh-nav__elemLink {
  text-transform: none;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.ndh-nav__elemLink {
  text-transform: uppercase;
  display: block;
  padding-top: 0.9rem;
  padding-bottom: 0.75rem;
}
@media (min-width: 769px) {
  .ndh-nav__elemLink {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

.ndh-artistNav {
  position: fixed;
  top: 4rem;
  z-index: 3;
  width: 100%;
}
body.ndh-js-mobileHide .ndh-artistNav {
  display: none;
}

.ndh-container {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  opacity: 1;
  height: auto;
  background: white;
  overflow: visible;
  transition: opacity 0.2s linear 0s, margin 0.2s linear 0s;
}
@media (orientation: landscape) {
  .ndh-container--titleImage, .ndh-container--artist, .ndh-container--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-container--titleImage, .ndh-container--artist, .ndh-container--exhibition {
    max-width: 87.5%;
  }
}
@media (orientation: landscape) {
  .ndh-container--contentColumn {
    max-width: 62.5vw;
  }
}
@media (min-width: 768px) {
  .ndh-container + .ndh-container, .ndh-search + .ndh-container {
    margin-top: 3.6rem;
  }
}
@media (min-width: 768px) {
  .ndh-container--2columns, .ndh-container__2columns {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
  }
  .ndh-container--2columns > *, .ndh-container__2columns > * {
    position: relative;
    box-sizing: border-box;
    flex: 0 1 auto;
  }
  .ndh-container--2columns > *:nth-child(1), .ndh-container__2columns > *:nth-child(1) {
    width: calc(50% - 0.95rem);
    order: 1;
    margin-top: 0;
    margin-left: 0;
  }
  .ndh-container--2columns > *:nth-child(2), .ndh-container__2columns > *:nth-child(2) {
    width: calc(50% - 0.95rem);
    order: 2;
    margin-top: 0;
    margin-left: 1.8981rem;
  }
}
@media (min-width: 768px) {
  .ndh-container--3columns, .ndh-container__3columns {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
  }
  .ndh-container--3columns > *, .ndh-container__3columns > * {
    position: relative;
    box-sizing: border-box;
    flex: 0 1 auto;
  }
  .ndh-container--3columns > *:nth-child(1), .ndh-container__3columns > *:nth-child(1) {
    width: calc(33.3333333333% - 1.2666666667rem);
    order: 1;
    margin-top: 0;
    margin-left: 0;
  }
  .ndh-container--3columns > *:nth-child(2), .ndh-container__3columns > *:nth-child(2) {
    width: calc(33.3333333333% - 1.2666666667rem);
    order: 2;
    margin-top: 0;
    margin-left: 1.8981rem;
  }
  .ndh-container--3columns > *:nth-child(3), .ndh-container__3columns > *:nth-child(3) {
    width: calc(33.3333333333% - 1.2666666667rem);
    order: 3;
    margin-top: 0;
    margin-left: 1.8981rem;
  }
}
.ndh-container--3columns--hidable.ndh-js-hidden, .ndh-container__3columns--hidable.ndh-js-hidden {
  display: none;
}
.ndh-container__column {
  opacity: 1;
  height: auto;
  background: white;
  overflow: visible;
  transition: opacity 0.2s linear 0s, margin 0.2s linear 0s;
}
.ndh-container__column.ndh-js-hidden {
  opacity: 0;
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: opacity 0.1s linear 0s, margin 0.1s linear 0s;
}
.ndh-container.ndh-js-hidden {
  opacity: 0;
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: opacity 0.1s linear 0s, margin 0.1s linear 0s;
}

.ndh-linkList {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-top: 0.9rem;
  margin-bottom: 2.7rem;
}
@media (orientation: landscape) {
  .ndh-linkList--titleImage, .ndh-linkList--artist, .ndh-linkList--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-linkList--titleImage, .ndh-linkList--artist, .ndh-linkList--exhibition {
    max-width: 87.5%;
  }
}
.ndh-linkList--sozial {
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.ndh-linkList--artist {
  padding-top: 1.9rem;
  margin-bottom: 3.6rem;
}
.ndh-linkList--imprint {
  padding-top: 1.9rem;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  max-width: none;
}
.ndh-linkList__elem {
  margin-bottom: 0.9rem;
  margin-right: 1rem;
}
.ndh-linkList--imprint .ndh-linkList__elem, .ndh-linkList__elem:last-child {
  margin-bottom: 0;
}
.ndh-linkList__link {
  text-transform: uppercase;
  position: relative;
}

.ndh-contentImages {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}
@media (orientation: landscape) {
  .ndh-contentImages--titleImage, .ndh-contentImages--artist, .ndh-contentImages--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-contentImages--titleImage, .ndh-contentImages--artist, .ndh-contentImages--exhibition {
    max-width: 87.5%;
  }
}
.ndh-container + .ndh-contentImages {
  margin-top: 2.7rem;
}
.ndh-contentImages figcaption {
  margin-top: 0.75rem;
  margin-bottom: 0;
}
@media (orientation: landscape) {
  .ndh-contentImages figcaption {
    max-width: 62.5vw;
  }
}
.ndh-contentImages p {
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.ndh-contentImages__placeholder {
  position: relative;
}
.ndh-contentImages__elem {
  margin-bottom: 5rem;
  max-width: 100%;
  height: auto;
}
.ndh-pageExhibitions--focus .ndh-contentImages__elem {
  margin-bottom: 1.9rem;
}
@media (orientation: landscape) {
  .ndh-contentImages__elem {
    max-width: 62.5vw;
    height: auto;
  }
}
.ndh-contentImages__elem img {
  width: 100%;
  position: absolute;
}
@media (min-width: 768px) {
  .ndh-contentImages__elem--single {
    width: 50%;
    padding-right: 0.45rem;
  }
}
.ndh-contentImages__elem--double img {
  bottom: 0;
}
@media (min-width: 768px) {
  .ndh-contentImages__elem--double {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
  }
  .ndh-contentImages__elem--double > * {
    position: relative;
    box-sizing: border-box;
    flex: 0 1 auto;
  }
  .ndh-contentImages__elem--double > *:nth-child(1) {
    width: calc(50% - 1.5rem);
    order: 1;
    margin-top: 0;
    margin-left: 0;
  }
  .ndh-contentImages__elem--double > *:nth-child(2) {
    width: calc(50% - 1.5rem);
    order: 2;
    margin-top: 0;
    margin-left: 2.997rem;
  }
}
.ndh-contentImages__elem--fullWidth img {
  width: 100%;
  max-width: none;
}
@media (orientation: landscape) {
  .ndh-contentImages__elem--fullWidth img {
    max-width: none;
  }
}
.ndh-contentImages__elem--contact {
  max-width: none;
}
@media (orientation: landscape) {
  .ndh-contentImages__elem--contact {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .ndh-contentImages__elem--contact {
    max-width: none;
  }
}
.ndh-contentImages__elem--contact img {
  position: static;
}

.ndh-artistList {
  overflow: hidden;
  position: relative;
}
body.ndh-js-colorSet1 .ndh-artistList {
  background-color: #C5E441;
}
body.ndh-js-colorSet2 .ndh-artistList {
  background-color: #48A70B;
}
body.ndh-js-colorSet3 .ndh-artistList {
  background-color: #B9E4DB;
}
body.ndh-js-colorSet4 .ndh-artistList {
  background-color: #8EC0BD;
}
body.ndh-js-colorSet5 .ndh-artistList {
  background-color: #37BAB4;
}
body.ndh-js-colorSet6 .ndh-artistList {
  background-color: #007F9E;
}
body.ndh-js-colorSet7 .ndh-artistList {
  background-color: #3D7BAE;
}
body.ndh-js-colorSet8 .ndh-artistList {
  background-color: #274C80;
}
body.ndh-js-colorSet9 .ndh-artistList {
  background-color: #1E2C8D;
}
body.ndh-js-colorSet10 .ndh-artistList {
  background-color: #EFAF05;
}
body.ndh-js-colorSet11 .ndh-artistList {
  background-color: #FF8724;
}
body.ndh-js-colorSet12 .ndh-artistList {
  background-color: #B32612;
}
body.ndh-js-colorSet13 .ndh-artistList {
  background-color: #8F1B42;
}
body.ndh-js-colorSet14 .ndh-artistList {
  background-color: #682E57;
}
body.ndh-js-colorSet15 .ndh-artistList {
  background-color: #C6DACF;
}
.ndh-artistList__elem {
  font-size: 2.8875rem;
  line-height: 1.15;
  border-bottom: 1px solid #FFFFFF;
  color: default;
  display: block;
  padding-bottom: 0.5rem;
  padding-left: 0.9rem;
  padding-right: 0;
  padding-top: 0.5rem;
  position: relative;
  transition: none;
}
@media (min-width: 769px) {
  .ndh-artistList__elem {
    font-size: 3.3rem;
  }
}
@media (min-width: 504px) {
  .ndh-artistList__elem {
    font-size: 2.7vw;
    line-height: 1.33;
    border-bottom: none;
    display: inline;
    padding: 0;
    padding-right: 3rem;
  }
}
@media (min-width: 504px) and (orientation: portrait) {
  .ndh-artistList__elem {
    font-size: 4.2vw;
    line-height: 1.33;
  }
}
.ndh-artistList__elem:hover {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.1px #000000;
  text-stroke: 1.1px #000000;
}
.ndh-artistList__elem::before {
  border-bottom: 1px solid #FFFFFF;
  bottom: 0;
  content: "";
  left: -200vw;
  position: absolute;
  width: 400vw;
}
@media (min-width: 504px) {
  .ndh-artistList__list {
    padding-left: 3rem;
    padding-right: 1rem;
  }
}
.ndh-artistList__overlay {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ndh-video {
  position: relative;
  margin-bottom: 2.7rem;
}
.ndh-video--noMargin {
  margin-bottom: 0;
}
.ndh-video__wrapper {
  position: relative;
}
.ndh-video__playerBtn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
.ndh-video.ndh-js-visiblePlayer .ndh-video__playerBtn {
  display: none;
}
.ndh-video__icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9rem;
  transform: translate(-50%, -50%);
}
.ndh-video__wrapper:hover .ndh-video__icon {
  display: none;
}
.ndh-video__label {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ndh-video__wrapper:hover .ndh-video__label {
  display: block;
  color: #000000;
}
.ndh-video__preview--empty {
  padding-bottom: 30%;
}
.ndh-video__preview img {
  width: 100%;
  object-position: center;
  object-fit: none;
  height: 100%;
}
.ndh-video.ndh-js-visiblePlayer .ndh-video__preview {
  display: none;
}
.ndh-video__title {
  margin-top: 0.9rem;
}
.ndh-video__player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ndh-video__player--removeAbsolute {
  position: relative;
}
.ndh-video__player figure {
  position: relative;
}
.ndh-video__player figure:last-child {
  margin-bottom: 0;
}
.ndh-video__player figure::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.ndh-video__player figure > iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ndh-video__placeholder {
  position: relative;
  overflow: hidden;
}
.ndh-video__placeholder--noHeight {
  height: 0;
}

.ndh-newsList {
  border-bottom: solid #000000 1px;
}
.ndh-newsList__elem {
  border-top: solid #000000 1px;
}
.ndh-newsList__elem:first-child {
  border-top: 0;
}
.ndh-newsList__link {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  display: block;
  padding-bottom: 1rem;
}
@media (orientation: landscape) {
  .ndh-newsList__link--titleImage, .ndh-newsList__link--artist, .ndh-newsList__link--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-newsList__link--titleImage, .ndh-newsList__link--artist, .ndh-newsList__link--exhibition {
    max-width: 87.5%;
  }
}
.ndh-newsList__link[data-ndh-button-mode]:hover {
  cursor: pointer;
}
.ndh-newsList__title {
  font-size: 2.8875rem;
  line-height: 1.15;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
@media (min-width: 769px) {
  .ndh-newsList__title {
    font-size: 3.3rem;
  }
}
@media (min-width: 504px) {
  .ndh-newsList__title {
    font-size: 5.8625rem;
    line-height: 1.04;
  }
}
@media (min-width: 504px) and (min-width: 769px) {
  .ndh-newsList__title {
    font-size: 6.7rem;
  }
}
html:not(.mobile) .ndh-newsList__link[data-ndh-button-mode] .ndh-newsList__title:hover, html:not(.mobile) .ndh-newsList__title--linked:hover {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #000000;
  text-stroke: 1px #000000;
}
.ndh-newsList__imgWrapper {
  font-size: 0;
}
.ndh-newsList img {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  max-width: 100%;
}
@media (orientation: landscape) {
  .ndh-newsList img {
    max-width: 62.5vw;
  }
}
.ndh-newsList__text {
  margin-bottom: 0.9rem;
  text-transform: none;
}
.ndh-newsList__text--withPadding {
  padding-bottom: 1rem;
}
.ndh-newsList__text:hover {
  text-transform: none;
}
.ndh-newsList__accordionItemBody {
  position: relative;
  overflow: hidden;
  height: auto;
}
.ndh-newsList__accordionItemContent {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.1s, opacity 0.1s linear;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
[data-ndh-accordion-item][data-ndh-collapsed=false] .ndh-newsList__accordionItemContent {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  position: relative;
}

.ndh-body .ndh-search {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Then Style */
  border-radius: 0px;
  display: block;
  width: 100%;
  border: 0;
  border-top: solid #000000 1px;
  border-bottom: solid #000000 1px;
  margin: 3.6rem 0 0 0;
  padding: 2.7rem 0.9rem 2.7rem 0.9rem;
  font-size: 16px;
}
@media (min-width: 768px) {
  .ndh-body .ndh-search {
    font-size: inherit;
  }
}
.ndh-body .ndh-search::placeholder {
  font-weight: normal;
  color: #000000;
}
.ndh-body .ndh-search__emptyResultText {
  opacity: 0;
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: opacity 0.1s linear 0s, margin 0.1s linear 0s;
}
.ndh-body .ndh-search__emptyResultText.ndh-js-visible {
  opacity: 1;
  height: auto;
  background: white;
  overflow: visible;
  transition: opacity 0.2s linear 0s, margin 0.2s linear 0s;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  margin-top: 0.9rem;
}
@media (orientation: landscape) {
  .ndh-body .ndh-search__emptyResultText.ndh-js-visible--titleImage, .ndh-body .ndh-search__emptyResultText.ndh-js-visible--artist, .ndh-body .ndh-search__emptyResultText.ndh-js-visible--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-body .ndh-search__emptyResultText.ndh-js-visible--titleImage, .ndh-body .ndh-search__emptyResultText.ndh-js-visible--artist, .ndh-body .ndh-search__emptyResultText.ndh-js-visible--exhibition {
    max-width: 87.5%;
  }
}

.ndh-exhibitionList__elem {
  margin-bottom: 1.9rem;
  opacity: 1;
  height: auto;
  background: white;
  overflow: visible;
  transition: opacity 0.2s linear 0s, margin 0.2s linear 0s;
}
.ndh-pageExhibitions--focus .ndh-exhibitionList__elem {
  margin-bottom: 5rem;
}
.ndh-pageExhibitions--focus .ndh-exhibitionList__elem:last-child {
  margin-bottom: 5rem;
}
.ndh-exhibitionList__elem--lowercase {
  text-transform: none;
}
.ndh-exhibitionList__elem:last-child {
  margin-bottom: 0;
}
.ndh-exhibitionList__elem.ndh-js-hidden {
  opacity: 0;
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: opacity 0.1s linear 0s, margin 0.1s linear 0s;
}
.ndh-exhibitionList .ndh-exhibitionList__elemImg img {
  width: 100%;
  margin-bottom: 1.9rem;
}

.ndh-exhibitionPreview {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  display: block;
  margin-top: 3.6rem;
  margin-bottom: 0;
}
@media (orientation: landscape) {
  .ndh-exhibitionPreview--titleImage, .ndh-exhibitionPreview--artist, .ndh-exhibitionPreview--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-exhibitionPreview--titleImage, .ndh-exhibitionPreview--artist, .ndh-exhibitionPreview--exhibition {
    max-width: 87.5%;
  }
}
.ndh-exhibitionPreview__placeholder {
  position: relative;
  height: 0;
}
.ndh-exhibitionPreview__title {
  margin-bottom: 1.9rem;
}
.ndh-exhibitionPreview__metaTitleDate {
  text-transform: none;
}
.ndh-exhibitionPreview__img {
  height: 0;
  overflow: hidden;
  padding-bottom: 68.75%;
  position: relative;
  width: 100%;
}
.ndh-exhibitionPreview__img img {
  object-fit: cover;
  font-family: "object-fit: cover";
  height: 100%;
  position: absolute;
  user-select: none;
  width: 100%;
}
@supports (--var: 0) {
  .ndh-exhibitionPreview__img img {
    object-position: var(--focusX, 50%) var(--focusY, 50%);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .ndh-exhibitionPreview__img img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.ndh-newsletter__formContainer {
  border: 0;
  display: block;
  margin: 0 -0.9rem 0 -0.9rem;
  width: 300px;
  overflow: hidden;
  height: 0;
}
.ndh-newsletter__formContainer.ndh-js-open.ndh-js-collapsedForHeightMeasuring, .ndh-newsletter__formContainer.ndh-js-collapsedForHeightMeasuring {
  transition: height 0 linear 0s;
  height: 0 !important;
  transition: height 0s ease-out 0s !important;
  visibility: hidden;
}
.ndh-newsletter__form {
  font-size: 83%;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  border-spacing: 2.7rem;
  margin-top: 2.7rem;
  margin-bottom: 2.7rem;
}
@media (orientation: landscape) {
  .ndh-newsletter__form--titleImage, .ndh-newsletter__form--artist, .ndh-newsletter__form--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-newsletter__form--titleImage, .ndh-newsletter__form--artist, .ndh-newsletter__form--exhibition {
    max-width: 87.5%;
  }
}
.ndh-newsletter__form p {
  margin-bottom: 0;
}
.ndh-newsletter__form p:last-of-type {
  margin-bottom: 0;
}
.ndh-newsletter__form form + br,
.ndh-newsletter__form br + br {
  display: none;
}
.ndh-newsletter__form input {
  border: 0;
  border-bottom: solid rgba(0, 0, 0, 0.5) 1px;
  margin-bottom: 1.9rem;
}
@media (max-width: 503px) {
  .ndh-newsletter__form input {
    width: 100%;
    margin-bottom: 1.9rem;
  }
}
.ndh-newsletter__form input[type=text] {
  width: 100%;
}
.ndh-newsletter__form input[type=submit] {
  text-transform: uppercase;
  border: 0;
  margin-bottom: 0.9rem;
  padding: 0;
  background: transparent;
}
.ndh-newsletter__form input[type=submit]:hover {
  transition: color 0.1s linear 0s;
  color: rgba(0, 0, 0, 0.5);
}
.ndh-newsletter__form a, .ndh-newsletter__form button {
  text-transform: uppercase;
  margin-bottom: 0.9rem;
}
.ndh-newsletter__form a:hover, .ndh-newsletter__form button:hover {
  transition: color 0.1s linear 0s;
  color: rgba(0, 0, 0, 0.5);
}
.ndh-newsletter__form > div:last-child {
  display: none;
}
.ndh-newsletter__ifame {
  border: 0;
  display: block;
  width: 100%;
  width: calc(100% + 1.8rem);
  margin: 0 -0.9rem 0 -0.9rem;
}
.ndh-newsletter__ifame.ndh-js-open.ndh-js-collapsedForHeightMeasuring, .ndh-newsletter__ifame.ndh-js-collapsedForHeightMeasuring {
  transition: height 0 linear 0s;
  height: 0 !important;
  transition: height 0s ease-out 0s !important;
  visibility: hidden;
}
.ndh-newsletter__body {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
}
@media (orientation: landscape) {
  .ndh-newsletter__body--titleImage, .ndh-newsletter__body--artist, .ndh-newsletter__body--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-newsletter__body--titleImage, .ndh-newsletter__body--artist, .ndh-newsletter__body--exhibition {
    max-width: 87.5%;
  }
}
.ndh-newsletter__body p {
  margin-bottom: 0;
}
.ndh-newsletter__body p:last-of-type {
  margin-bottom: 0;
}
.ndh-newsletter__body form + br,
.ndh-newsletter__body br + br {
  display: none;
}
.ndh-newsletter__body table {
  border-spacing: 2.7rem;
  margin-top: 2.7rem;
  margin-bottom: 2.7rem;
}
.ndh-newsletter__body table input {
  border: 0;
  border-bottom: solid rgba(0, 0, 0, 0.5) 1px;
}
@media (max-width: 503px) {
  .ndh-newsletter__body table input {
    width: 100%;
    margin-bottom: 1.9rem;
  }
}
@media (max-width: 503px) {
  .ndh-newsletter__body table td {
    display: inline-block;
  }
}
@media (min-width: 504px) {
  .ndh-newsletter__body table td {
    padding-bottom: 0.9rem;
  }
}
.ndh-newsletter__body td:first-child {
  padding-right: 0.9rem;
}
.ndh-newsletter__body .list {
  list-style: none;
  margin: 0.9rem 0 0 0;
  padding: 0;
}
.ndh-newsletter__body .list .list {
  margin: 0 0 0.9rem 0;
}
.ndh-newsletter__body .list .list input {
  margin-right: 0.9rem;
}
.ndh-newsletter__body input[type=submit] {
  text-transform: uppercase;
  border: 0;
  margin-bottom: 0.9rem;
  padding: 0;
  background: transparent;
}
.ndh-newsletter__body input[type=submit]:hover {
  transition: color 0.1s linear 0s;
  color: rgba(0, 0, 0, 0.5);
}
.ndh-newsletter__body a {
  text-transform: uppercase;
}
.ndh-newsletter__body > div:last-child {
  display: none;
}

.ndh-readMore {
  margin-bottom: 3.6rem;
}
.ndh-readMore + .ndh-readMore {
  margin-bottom: 5rem;
}
.ndh-readMore__head {
  margin-top: 2.7rem;
  margin-bottom: 2.7rem;
  max-width: 66rem;
}
.ndh-readMore__head--exhibition {
  margin-bottom: 0.9rem;
}
.ndh-readMore__btn {
  margin-top: 1.9rem;
  display: block;
  overflow: hidden;
}
.ndh-readMore__txt {
  display: none;
}

.ndh-pageExhibitions__desktopHead {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
}
.ndh-pageExhibitions__desktopHead--sticky {
  position: sticky;
  top: 4rem;
  z-index: 3;
}
body.ndh-js-mobileHide .ndh-pageExhibitions__desktopHead--sticky {
  top: 0;
}
@media (max-width: 767px) {
  .ndh-pageExhibitions__desktopHead:not(.ndh-pageExhibitions__desktopHead--focus) {
    display: none;
  }
}
.ndh-pageExhibitions__columnsContainer {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
}
.ndh-pageExhibitions__headText {
  margin-bottom: 0;
}
.ndh-pageExhibitions__mobileHead {
  display: block;
}
@media (min-width: 768px) {
  .ndh-pageExhibitions__mobileHead {
    display: none;
  }
}
.ndh-pageExhibitions__city {
  margin-top: 3.6rem;
}
@media (min-width: 768px) {
  .ndh-pageExhibitions__city {
    margin-top: 0;
  }
}
.ndh-pageExhibitions__city.ndh-js-hidden {
  height: 0px;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.ndh-pageExhibitions__focusItems {
  margin-top: 3.6rem;
}
.ndh-pageExhibitions__intro {
  margin-top: 3.6rem;
}

.ndh-externalLink {
  margin-left: 0;
  transition: margin-left 0.1s linear 0s, color 0.1s linear 0s;
}
.ndh-externalLink:hover {
  transition: margin-left 0.1s linear 0s, color 0.1s linear 0s;
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
}
.ndh-externalLink--onlyEase:hover {
  transition: color 0.1s linear 0s;
  color: rgba(0, 0, 0, 0.5);
}

.ndh-doubleImages {
  position: relative;
}
.ndh-doubleImages::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 3rem;
}
.ndh-doubleImages__texts {
  margin-bottom: 2.7rem;
}
.ndh-doubleImages__columns {
  margin-bottom: 0;
}
.ndh-doubleImages__column:nth-child(1) {
  margin-bottom: 2.7rem;
}
@media (min-width: 768px) {
  .ndh-doubleImages__column:nth-child(1) {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .ndh-doubleImages__columns:hover .ndh-doubleImages__column {
    opacity: 0.1;
  }
  .ndh-doubleImages .ndh-doubleImages__column .ndh-doubleImages__image--right figure {
    transform: translateX(calc(-50% - 1.5rem));
  }
  .ndh-doubleImages .ndh-doubleImages__column:hover {
    opacity: 1;
  }
  .ndh-doubleImages .ndh-doubleImages__column:hover ~ .ndh-doubleImages__column {
    opacity: 0.1;
  }
  .ndh-doubleImages .ndh-doubleImages__column:hover .ndh-doubleImages__image {
    pointer-events: none;
    position: relative;
    overflow: visible;
    z-index: 3;
  }
  .ndh-doubleImages .ndh-doubleImages__column:hover .ndh-doubleImages__image--right {
    left: calc(-100% - 3rem);
    opacity: 1;
  }
  .ndh-doubleImages .ndh-doubleImages__column:hover .ndh-doubleImages__image--right figure {
    transform: translateX(0%);
  }
  .ndh-doubleImages__image {
    width: 100%;
    overflow: hidden;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .ndh-doubleImages__image figure {
    width: calc(200% + 3rem);
  }
}

.ndh-imprint__head {
  margin-top: 1.9rem;
  margin-bottom: 1.9rem;
}
.ndh-imprint__exhibitionLink {
  display: inline-block;
  padding: 0.4em 0.6em;
}
body.ndh-js-colorSet1 .ndh-imprint__exhibitionLink {
  background-color: rgba(197, 228, 65, 0.5);
}
body.ndh-js-colorSet2 .ndh-imprint__exhibitionLink {
  background-color: rgba(72, 167, 11, 0.5);
}
body.ndh-js-colorSet3 .ndh-imprint__exhibitionLink {
  background-color: rgba(185, 228, 219, 0.5);
}
body.ndh-js-colorSet4 .ndh-imprint__exhibitionLink {
  background-color: rgba(142, 192, 189, 0.5);
}
body.ndh-js-colorSet5 .ndh-imprint__exhibitionLink {
  background-color: rgba(55, 186, 180, 0.5);
}
body.ndh-js-colorSet6 .ndh-imprint__exhibitionLink {
  background-color: rgba(0, 127, 158, 0.5);
}
body.ndh-js-colorSet7 .ndh-imprint__exhibitionLink {
  background-color: rgba(61, 123, 174, 0.5);
}
body.ndh-js-colorSet8 .ndh-imprint__exhibitionLink {
  background-color: rgba(39, 76, 128, 0.5);
}
body.ndh-js-colorSet9 .ndh-imprint__exhibitionLink {
  background-color: rgba(30, 44, 141, 0.5);
}
body.ndh-js-colorSet10 .ndh-imprint__exhibitionLink {
  background-color: rgba(239, 175, 5, 0.5);
}
body.ndh-js-colorSet11 .ndh-imprint__exhibitionLink {
  background-color: rgba(255, 135, 36, 0.5);
}
body.ndh-js-colorSet12 .ndh-imprint__exhibitionLink {
  background-color: rgba(179, 38, 18, 0.5);
}
body.ndh-js-colorSet13 .ndh-imprint__exhibitionLink {
  background-color: rgba(143, 27, 66, 0.5);
}
body.ndh-js-colorSet14 .ndh-imprint__exhibitionLink {
  background-color: rgba(104, 46, 87, 0.5);
}
body.ndh-js-colorSet15 .ndh-imprint__exhibitionLink {
  background-color: rgba(198, 218, 207, 0.5);
}

.ndh-buttonHamburger {
  display: block;
  z-index: 3;
  width: 2rem;
  margin-top: 0.3rem;
  transition: opacity 0.1s linear 0s;
}
@media (min-width: 768px) {
  .ndh-buttonHamburger {
    margin-top: 0.4rem;
  }
}
.ndh-buttonHamburger:hover {
  opacity: 0.5;
}
.ndh-buttonHamburger span {
  display: block;
  position: relative;
  height: 1px;
  background-color: #000000;
  margin-bottom: 0.5rem;
}
.ndh-buttonHamburger span:nth-child(1) {
  top: 0;
}
.ndh-buttonHamburger span:nth-child(3) {
  bottom: 0;
}
body.ndh-js-mainMenuOpen .ndh-buttonHamburger span:nth-child(1) {
  transform: rotate(45deg);
  top: 0.6rem;
}
body.ndh-js-mainMenuOpen .ndh-buttonHamburger span:nth-child(2) {
  width: 0;
}
body.ndh-js-mainMenuOpen .ndh-buttonHamburger span:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 0.6rem;
}

.ndh-page__additonalText {
  margin-bottom: 2.7rem;
  padding-left: 1rem;
  padding-top: 4rem;
}

.ndh-exhibitionLink {
  display: inline-block;
  text-transform: none;
}
.ndh-pageExhibitions .ndh-exhibitionLink {
  font-size: 2.8875rem;
  line-height: 1.15;
}
@media (min-width: 769px) {
  .ndh-pageExhibitions .ndh-exhibitionLink {
    font-size: 3.3rem;
  }
}
.ndh-exhibitionLink:hover {
  text-transform: none;
}
body.ndh-js-colorSet1 .ndh-exhibitionLink {
  color: rgba(197, 228, 65, 0.7);
}
body.ndh-js-colorSet2 .ndh-exhibitionLink {
  color: rgba(72, 167, 11, 0.7);
}
body.ndh-js-colorSet3 .ndh-exhibitionLink {
  color: rgba(185, 228, 219, 0.7);
}
body.ndh-js-colorSet4 .ndh-exhibitionLink {
  color: rgba(142, 192, 189, 0.7);
}
body.ndh-js-colorSet5 .ndh-exhibitionLink {
  color: rgba(55, 186, 180, 0.7);
}
body.ndh-js-colorSet6 .ndh-exhibitionLink {
  color: rgba(0, 127, 158, 0.7);
}
body.ndh-js-colorSet7 .ndh-exhibitionLink {
  color: rgba(61, 123, 174, 0.7);
}
body.ndh-js-colorSet8 .ndh-exhibitionLink {
  color: rgba(39, 76, 128, 0.7);
}
body.ndh-js-colorSet9 .ndh-exhibitionLink {
  color: rgba(30, 44, 141, 0.7);
}
body.ndh-js-colorSet10 .ndh-exhibitionLink {
  color: rgba(239, 175, 5, 0.7);
}
body.ndh-js-colorSet11 .ndh-exhibitionLink {
  color: rgba(255, 135, 36, 0.7);
}
body.ndh-js-colorSet12 .ndh-exhibitionLink {
  color: rgba(179, 38, 18, 0.7);
}
body.ndh-js-colorSet13 .ndh-exhibitionLink {
  color: rgba(143, 27, 66, 0.7);
}
body.ndh-js-colorSet14 .ndh-exhibitionLink {
  color: rgba(104, 46, 87, 0.7);
}
body.ndh-js-colorSet15 .ndh-exhibitionLink {
  color: rgba(198, 218, 207, 0.7);
}

.ndh-contact {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border-bottom: 1px solid #000000;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.7rem;
  flex-wrap: wrap;
}
@media (orientation: landscape) {
  .ndh-contact--titleImage, .ndh-contact--artist, .ndh-contact--exhibition {
    max-width: 62.5%;
  }
}
@media (max-width: 1365px) and (min-width: 769px) {
  .ndh-contact--titleImage, .ndh-contact--artist, .ndh-contact--exhibition {
    max-width: 87.5%;
  }
}
.ndh-contact__column {
  width: calc(100% - 6rem);
}
@media (min-width: 504px) {
  .ndh-contact__column {
    width: calc(50% - 3rem);
  }
}
@media (min-width: 1024px) {
  .ndh-contact__column {
    width: calc(33.3333333333% - 2rem);
  }
}
.ndh-contact--additonal .ndh-contact__column {
  width: 100%;
}

.ndh-slideshow {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.ndh-slideshow__button {
  width: 50%;
  padding: 2rem;
  fill: currentColor;
  stroke: none;
}
.ndh-slideshow__button:hover {
  color: rgba(0, 0, 0, 0.5);
  fill: rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) {
  .ndh-slideshow__button {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: auto;
    padding: 1rem;
  }
}
@media (min-width: 1024px) {
  .ndh-slideshow__button {
    padding: 2rem;
  }
}
.ndh-slideshow.ndh-js-loading .ndh-slideshow__button, .ndh-slideshow.ndh-js-disabled .ndh-slideshow__button {
  pointer-events: none;
  opacity: 0.25;
}
.ndh-slideshow.ndh-js-loading .ndh-slideshow__button:hover, .ndh-slideshow.ndh-js-disabled .ndh-slideshow__button:hover {
  cursor: default;
}
.ndh-slideshow__button--prev {
  left: 0;
}
@media (min-width: 768px) {
  .ndh-slideshow__button--prev {
    left: auto;
    margin-right: 0;
    right: 100%;
  }
}
.ndh-slideshow__button--prev svg {
  transform: scaleX(-1);
}
.ndh-slideshow__button--next {
  right: 0;
}
@media (min-width: 768px) {
  .ndh-slideshow__button--next {
    left: 100%;
    margin-left: 0;
    right: auto;
  }
}
.ndh-slideshow__button svg {
  height: 2.7rem;
  width: 1.9rem;
}
.ndh-slideshow__slide {
  transition: opacity 0.2s linear 0s;
  flex-shrink: 0;
  opacity: 0;
  pointer-events: none;
  position: relative;
  width: 100%;
}
.ndh-slideshow__slide:nth-of-type(1) {
  left: 0%;
}
.ndh-slideshow__slide:nth-of-type(2) {
  left: -100%;
}
.ndh-slideshow__slide:nth-of-type(3) {
  left: -200%;
}
.ndh-slideshow__slide.ndh-js-current {
  opacity: 1;
  pointer-events: all;
  z-index: 1;
}
.ndh-slideshow__slides {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}