
.ndh-pageExhibitions {

	&__desktopHead {
		margin-top: $baseXL;
		margin-bottom: $baseXL;

		&--sticky {
			position: sticky;
			top: $mainMenuHeight;
			z-index: 3;

			body.ndh-js-mobileHide & {
				top: 0;
			}
		}
		//NOTE: desktop in mediaQuery
		&:not(&--focus) {
			@include media('<small') {
				display: none;
			}
		}
		// ndh-js-mobileHide
	}

	&__columnsContainer {
		margin-top: $baseXL;
		margin-bottom: $baseXL;
	}

	&__headText {
		margin-bottom: 0;
	}

	&__mobileHead {
		display: block;

		@include media('>=small') {
			display: none;
		}
	}


	&__city {
		margin-top: $baseXL;
		//margin-bottom: $baseXL;

		@include media('>=small') {
			margin-top: 0;
		}

		&.ndh-js-hidden {
			height: 0px;
			overflow: hidden;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
	}


	&__focusItems {
		margin-top: $baseXL;
	}


	&__intro {
		margin-top: $baseXL;
	}


	// &__otherExLink {
	// 	&--mexico {
	// 		display: inline-block;
	// 		margin-top: $baseXL;
	// 		@include ndh-fontSize(h2);

	// 		@include media('>=small') {
	// 			margin-top: 0;
	// 		}
	// 	}
	// }
}
